export default {
	data: {
		selectedImageIndex: undefined
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		},
		bannerVideo: function () {
			var currentDate = new Date();
			var season = 'winter';
			var day = currentDate.getDate();
			var month = currentDate.getMonth() + 1;
			if (month >= 3 && month < 10) {
				if (month == 3 && day >= 15) {
					season = 'summer';
				} else if (month != 3) {
					season = 'summer';
				}

			}
			/*if (season == 'summer') {
				return 'https://www.youtube.com/embed/Az3zp5iB3xM?playlist=Az3zp5iB3xM&loop=1&autoplay=1';
			} else {
				return 'https://www.youtube.com/embed/S-ivNAYxHno?playlist=S-ivNAYxHno&loop=1&autoplay=1';
			}*/
			if (season == 'summer') {
				return 'https://voffice-member-big-files.s3-eu-west-1.amazonaws.com/1620/Sommer_in_der_Ferienwelt_Winterberg.mp4';
			} else {
				return 'https://voffice-member-big-files.s3-eu-west-1.amazonaws.com/1620/Winter_in_der_Ferienwelt_Winterberg.mp4';
			}
		}
	},
	methods: {
		allowGoogleMaps: function () {
			console.log('allowing google maps...');
			localStorage.setItem('allowGoogleMaps', true);
			location.reload();
		},
		preventGoogleMaps: function () {
			localStorage.setItem('allowGoogleMaps', false);
			location.reload();
		},
		canShowGoogleMaps: function () {
			var GoogleMaps = localStorage.getItem("allowGoogleMaps");
			if (GoogleMaps === 'true') {
				return true;
			}
			return false;
		}
	}

};