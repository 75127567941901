import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

	},

	methods: {

		navbarToggle: function () {

			var nav = this.$el;

			if (nav.className === "vo-nav-bar") {
				nav.className += " opened";
			} else {
				nav.className = "vo-nav-bar";
			}


		},
		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURI(this.name);

			}
		}



	}
};