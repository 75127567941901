<template>
    <div class="datepicker-trigger">

        <slot></slot>

        <airbnb-style-datepicker :trigger-element-id="triggerId"
            :close-after-select="true"
            :inline="inline"
            :show-shortcuts-menu-trigger="false"
            :show-action-buttons="false"
            :offset-y="10"
            :mode="'range'"
            :fullscreen-mobile="fullscreenMobile"
            :disabled-dates="disabledDays"
            :customized-dates="calClasses"
            :min-date="minDate"
            :end-date="maxDate"
            :date-one="from"
            :date-two="till"
            @opened="onOpened"
            @closed="onClosed"
            :mobile-header="mobileHeader"
            @date-one-selected="onFromSelected"
            @date-two-selected="onTillSelected" />
    </div>
</template>

<script>
    import {
    	isBlank
    }
    from 'voUtils/tools.js';
    import V from 'voUtils/V.js'


    import DateEx from 'voUtils/DateEx.js'

    export default {
    	voVueComponent: 'voffice-daterange',
    	voMsgCodes: [
    		'weekdayslist.short',
    		'monthlist.full',
    		'weekdayslist'
    	],
    	data() {
    		return {
    			needTill: false,
    			nextArrivalDate: undefined,
    			disabledArrivalDays: [],
    			disabledDepartureDays: [],
    			calClasses: [],
    			cal: undefined
    		}
    	},
    	props: {
    		from: String,
    		till: String,
    		triggerId: String,
    		inline: Boolean,
    		fullscreenMobile: Boolean,
    		getCal: Function
    	},
    	computed: {
    		disabledDays: function() {
    			return this.needTill ? this.disabledDepartureDays : this.disabledArrivalDays
    		},
    		mobileHeader: function() {
    			if (this.needTill) {
    				return this.voMsg("daterange.till");
    			} else {
    				return this.voMsg("daterange.from");
    			}
    		},
    		minDate: function() {
    			if (this.needTill) {
    				return DateEx.plusDays(this.from, 1);
    			} else {
    				return DateEx.interfaceFormat(DateEx.getToday());
    			}

    		},
    		maxDate: function() {
    			if (this.needTill) {
    				return this.nextArrivalDate;
    			} else {
    				return undefined;
    			}

    		}

    	},

    	methods: {
    		onOpened: function() {
    			if (this.getCal && !this.cal) {
    				this.getCal((cal) => {
    					this.cal = cal;
    					var day = DateEx.localDate(cal.availabilityUpdate.split('T')[0]);

    					var arrivals = [];
    					var departures = [];
    					var notAvailable = [];

    					var last;
    					for (let a of cal.availability) {
    						if (a == 'Y') {
    							if (last == 'N' || last == 'Q') {
    								departures.push(DateEx.interfaceFormat(day));
    							}
    						} else {
    							if (last == 'Y') {
    								arrivals.push(DateEx.interfaceFormat(day));
    							} else {
    								notAvailable.push(DateEx.interfaceFormat(day));
    							}
    						}
    						day = DateEx.plusDays(day, 1)
    						last = a;
    					}

    					this.calClasses = [{
    						cssClass: 'arrival',
    						dates: arrivals
    					}, {
    						cssClass: 'departure',
    						dates: departures
    					}, {
    						cssClass: 'not-available',
    						dates: notAvailable
    					}];

    					var disabledArrivalDays = []
    					var day = DateEx.localDate(cal.availabilityUpdate.split('T')[0]);
    					for (let a of cal.changeOver) {
    						if (a == 'X' || a == 'O') {
    							disabledArrivalDays.push(DateEx.interfaceFormat(day));
    						}
    						day = DateEx.plusDays(day, 1)
    					}

    					this.disabledArrivalDays = disabledArrivalDays;

    				});
    			}
    		},
    		onClosed: function() {

    			setTimeout(() => {
    				this.$emit('closed');
    			}, 50);
    		},
    		onFromSelected: function(d) {
    			this.needTill = true;
    			this.$emit('update:from', d);
    			this.$emit('update:till', undefined);

    			if (this.cal) {

    				//nextArrivalDate
    				let day = DateEx.localDate(this.cal.availabilityUpdate.split('T')[0]);
    				let from = DateEx.localDate(d);

    				let nextArrivalDate;
    				for (let a of this.cal.availability) {
    					if (day > from && a != 'Y') {
    						nextArrivalDate = day;

    						break;
    					}
    					day = DateEx.plusDays(day, 1);
    				}

    				this.nextArrivalDate = DateEx.interfaceFormat(nextArrivalDate);

    				day = DateEx.localDate(this.cal.availabilityUpdate.split('T')[0]);

    				var disabledDepartureDays = [];
    				for (let a of this.cal.changeOver) {
    					if (nextArrivalDate && day > nextArrivalDate) {
    						break;
    					} else if (day > from && (a == 'X' || a == 'I')) {
    						disabledDepartureDays.push(DateEx.interfaceFormat(day));
    					}
    					day = DateEx.plusDays(day, 1)
    				}

    				this.disabledDepartureDays = disabledDepartureDays;

    			}

    		},
    		onTillSelected: function(d) {
    			this.needTill = isBlank(d) && this.from;
    			this.$emit('update:till', d);
    			setTimeout(() => {
    				this.$emit('on-tilldate-selected');
    			}, 50);

    		}
    	}
    }
</script>

<style scoped>
    .datepicker-trigger {
    	--cal_not_available: #cd7898;
    }

    .asd__day--not-available {
    	background: var(--cal_not_available) !important;
    }

    .asd__day--arrival {
    	background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%);
    }

    .asd__day--departure {
    	background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%);
    }
</style>